.App {
  text-align: center;
}

.navbar {
  background-color: #A93D49;
}

.home-title {
  text-shadow:  -2px 0 rgb(230, 143, 143), 0 2px rgb(56, 26, 30), 2px 0 rgb(56, 26, 30), 0 -2px rgb(230, 143, 143);
}

.first-name {
  color: #EAB996;
}

.last-name {
  color: #CB7666;
}

.about-title {
  color: #F1ECE4;
  text-shadow:  -2px 0 #CB7666, 0 2px #CB7666, 2px 0 #EAB996, 0 -2px #EAB996;

}

.logo-images {
  border-color: #CB7666;
  width: 50%;

}

.me-title {
  color: #A93D49;
  text-shadow:  -2px 0 #A93D49, 0 2px #A93D49, 2px 0 #EAB996, 0 -2px #EAB996;

}

.resume-info {
  height: 10%;
  width: 30%;
}

.resume {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.logo{
  size: 1%;
}

.buttons {
  justify-content: space-evenly; 
}

.button {
  background-color: #A93D49;
}

.sobutton {
  background-color: #e27b87;
}

.hero {
  border-color: #EAB996;
}